// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-de-nosotros-js": () => import("./../src/pages/acerca-de-nosotros.js" /* webpackChunkName: "component---src-pages-acerca-de-nosotros-js" */),
  "component---src-pages-aviso-de-privacidad-js": () => import("./../src/pages/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cuenta-estandar-js": () => import("./../src/pages/cuenta-estandar.js" /* webpackChunkName: "component---src-pages-cuenta-estandar-js" */),
  "component---src-pages-cuenta-multidivisas-js": () => import("./../src/pages/cuenta-multidivisas.js" /* webpackChunkName: "component---src-pages-cuenta-multidivisas-js" */),
  "component---src-pages-cuenta-premium-js": () => import("./../src/pages/cuenta-premium.js" /* webpackChunkName: "component---src-pages-cuenta-premium-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mis-ahorros-js": () => import("./../src/pages/mis-ahorros.js" /* webpackChunkName: "component---src-pages-mis-ahorros-js" */),
  "component---src-pages-mis-amigos-js": () => import("./../src/pages/mis-amigos.js" /* webpackChunkName: "component---src-pages-mis-amigos-js" */),
  "component---src-pages-pagos-de-servicios-js": () => import("./../src/pages/pagos-de-servicios.js" /* webpackChunkName: "component---src-pages-pagos-de-servicios-js" */),
  "component---src-pages-presupuestos-js": () => import("./../src/pages/presupuestos.js" /* webpackChunkName: "component---src-pages-presupuestos-js" */),
  "component---src-pages-recompensas-js": () => import("./../src/pages/recompensas.js" /* webpackChunkName: "component---src-pages-recompensas-js" */),
  "component---src-pages-transferencias-de-dinero-js": () => import("./../src/pages/transferencias-de-dinero.js" /* webpackChunkName: "component---src-pages-transferencias-de-dinero-js" */)
}

